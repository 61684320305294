/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"

// import "./src/assets/bootstrap/bootstrap.min.css"
// import "./src/assets/bootstrap/jquery-3.4.1.slim.min.js"
// import "./src/assets/bootstrap/bootstrap.min.js"
// import "./src/assets/bootstrap/popper.min.js"

// import 'bootstrap/dist/css/bootstrap.css';
// import 'jquery/src/jquery.js';
// if (typeof window !== 'undefined') {
//    window.jQuery = window.$ = require('jQuery');
//    require('bootstrap');
// }
